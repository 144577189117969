<template>
  <section class="header about">
    <h1 style="font-weight: 800;">О компании</h1>
    <div class="about-wrap">
      <div>
        <img src="@/assets/images/Asyl.png" alt="" />
      </div>
      <div>
        <h3>История компании Асыл Арман Казахстан.</h3>
        <p>
          Компания «Асыл Арман Казахстан» была создана в 2005 г. и стала первой казахстанской компанией в MLM индустрии.
        </p>
        <h3>Этапы развития</h3>
        <h3 style="margin-top: 3px;">2005 год</h3>

        <p>Начало деятельности. Компания получила официальный юридический статус.</p>
        <h3>2006 год</h3>
        <p>
          Выход на рынок с оздоровительной продукцией. В ассортименте - косметический крем-бальзам и 6 наименований
          медовых бальзамов. Разработчики продукции сделали акцент на антипаразитарные свойства бальзамов. Открытие
          первого офиса в Шымкенте.
        </p>
      </div>
    </div>

    <div class="about-wrap">
      <div>
        <h3>2007 год</h3>
        Представлен новый ассортимент продукции: более 10 видов медовых бальзамов, масляные травяные бальзамы для людей,
        страдающих диабетом, фиточаи, жидкий кальций «Кальций+». Сеть потребителей и дистрибьюторов расширяется,
        натуральная оздоровительная продукция завоевывает популярность.

        <h3>2008 год</h3>

        Расширен спектр продукции, выпущены новые виды косметических и медовых бальзамов, масло черного тмина. Первые
        «Президентские каникулы» в Турции.
        <h3>2009 год</h3>

        Ассортимент продукции вырос до 41 наименования. Учреждено ТОО «Fortex-KZ» по производству жидкого кальция.
        «Президентские каникулы» в Таиланде.
        <h3>2010 год</h3>

        Компания становится обладателем Народного знака качества «Безупречно» от Лиги потребителей Казахстана. Получен
        статус «Золотые лидеры» - символ качества продукции и отличной деловой репутации бизнеса. Ассортиментная линейка
        составила более 50 наименований. «Президентские каникулы» в Египте.
      </div>
      <div>
        <img src="@/assets/images/about-2.png" alt="" />
      </div>
    </div>
    <div class="about-wrap">
      <div>
        <img src="@/assets/images/about-3.png" alt="" />
      </div>
      <div>
        <h3>2011 год</h3>
        Копилку наград пополнила золотая медаль Международной выставки «Юг Экспо Казахстан 2011». Новый вид продукции –
        сорбент-гель «Сорбент плюс». «Президентские каникулы» в Чехии и Франции.

        <h3>2012 год</h3>
        Работа «Asyl Arman» отмечена высшей наградой Оксфордского Саммита Лидеров - «Европейским знаком качества».
        Получен сертификат СТ РК ИСО -9001-2009 (iso 9001-9008). Компания вновь становится обладателем Знака качества.
        «Безупречно» от Лиги потребителей РК. Ассортимент пополнился средствами женской гигиены и косметикой,
        выпускаемой эксклюзивно под компанию «Asyl Arman». «Президентские каникулы» в Малайзии.

        <h3>2013 год</h3>
        Продукция компании «Asyl Arman» получила сертификат «Халал» от «Ассоциации Халал Индустрия Казахстана».
        Ассортимент составляет 10 линеек производства и 63 наименования БАДов. Получены три Золотые медали выставки
        «Food market-2013». Эфирные масла, сорбент-гель и зеленые чаи удостоены звания «Лучший продукт Казахстана 2013».
        «Президентские каникулы» в Италии.
      </div>
    </div>

    <div class="about-wrap">
      <div class="about-wrap__single">
        <h3>2014 год</h3>
        Ассортимент продукции пополнился товарами для гигиены полости рта – зубными щетками и пастой нескольких
        наименований. Президент Компании А.Н. Архабаева стала лауреатом Международной премии «Золотой Фонд регионов
        Казахстана». Президенту компании А.Н. Архабаевой вручен орден "Звезда славы - Экономика Казахстана". Компания
        удостоена награды - Национальный сертификат и медаль «Лидер Отрасли». «Президентские каникулы» в Турции.
        <h3>2015 год</h3>
        «Asyl Arman» представлен во всех крупных городах Казахстана, имеет более 30 представительств в РК, России и
        Кыргызстане. Компания пополнила ассортимент продукции сразу на 17 наименований, это востребованная корейская
        косметики класса «люкс». Всего «Asyl Arman» продвигает 85 видов продукции. «Президентские каникулы» в Индии. В
        компании создана автомобильная программа – при выполнении определенных условий партнеры от компании получают
        бонусы на покрытие кредита за машину – 27 человек приобрели автомобили

        <h3>2016 год</h3>
        <p>
          Ассортимент продукции пополнен БАДом «Клетчатка». Компания активизирует консалтинговую деятельность онлайн.
          Для дистрибьюторов и всех желающих проводит 2-мсячный онлайн – проект «Марафон победителей». Руководители и
          лидеры компании приняли участие в «Международном Конгрессе сетевой индустрии, который прошел 26-27 ноября 2016
          в Алматы. Большая команда компании «Asyl Arman» была удостоена наград в различных номинациях. «Президентские
          каникулы в ОАЭ».
        </p>
        <h3>2017 год</h3>
        <p>
          В компании создана жилищная программа – при выполнении определенных условий партнеры от компании получают
          бонусы на покрытие кредита за квартиру. «Президентские каникулы – морской круиз»
        </p>
        <h3>2019 год</h3>
        <p>«Президентские каникулы – Сарыагаш»</p>
        <h3>2021 – 2023 год</h3>
        <p>
          Подготовка к ребрендингу компании. Разрабатывается и запускаются пилотные проекты для определения наиболее
          интересной и жизнеспособной модели.
        </p>
        <img src="../../assets/images/pictures/about-banner.png" alt="" />
        <h3>
          Миссия компании: «Объединять людей, для которых важны здоровье, гармония в семье и финансовое благополучие,
          помогая им повышать качество жизни».
        </h3>
        <p>
          Основной вид деятельности Асыл Арман Казахстан– продвижение натуральной оздоровительной и гигиенической
          продукции. Компания ставит своей целью формирование культуры здорового образа жизни и профилактики
          заболеваний, В ассортиментной линейке: 27 видов БАДов: медовые бальзамы, эфирные масла, жидкий кальций «Са+»,
          крем-бальзамы, зеленый чай с травяными добавками, масло черного тмина, минерал гель «Сорбент плюс»; 11 видов
          средств гигиены: зубная паста и зубные щетки премиум-класса с частицами золота и серебра, натуральное мыло
          ручной работы, натуральная косметика премиум-класса. Продукция «Asyl Arman» прошла Государственную
          регистрацию, войдя в «Реестр биологически активных добавок РК». Качество подтверждено сертификатом Академии
          Питания РК и сертификатом «Халал».
        </p>
        <h3>Награды Компании</h3>
        <br>
        <ul style="padding-left: 20px;">
          <li>Знак «Безупречно» от Казахстанской Лиги потребителей (2010, 2012 гг).</li>
          <li>Золотая медаль Международной выставки «Юг Экспо Казахстан» (2011г).</li>
          <li>«Европейский знак качества» высшая награда Оксфордского Саммита Лидеров (2012 г.)</li>
          <li>Три золотые медали выставки «Food market-2013» в номинации «Лучший продукт Казахстана 2013».</li>
          <li>Международная премия «Золотой Фонд регионов Казахстана» (2014)</li>
          <li>Национальный сертификат и медаль "Лидер Отрасли» (2014).</li>
          <li>
            Орден "Звезда славы - Экономика Казахстана" – ген. директору ТОО «Корпорация «Asyl Arman» А.Н. Архабаевой
            (2014).
          </li>
          <li>Медаль "За заслуги и достижения», сертификаты "Руководитель года ", "Специалист года"(2014).</li>
          <li>
            Медаль Национальной Палаты предпринимателей РК «Атамекен» «За преданность своему делу» председателю Совета
            деловых женщин Палаты предпринимателей ЮКО, президенту компании «Асыл Арман» Алме Архабаевой.
          </li>
        </ul>
        <p class="last__p">
          Руководство компании «АсылАрманКазахстан» понимает свою ответственность за будущее нового поколения и
          прилагает все условия к пропаганде здорового образа жизни и обеспечения натуральной продукцией должного
          качества для возможности всем желающим обеспечить себе возможность оздоровления и восстановления здоровья.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUs',
  data: () => ({}),

  computed: {},

  async mounted() {},

  methods: {},
}
</script>

<style>
.about-wrap > div{
  padding: 10px;
}
</style>
